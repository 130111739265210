import {useEffect, useState} from 'react'

export const getNetworkStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true

export const useNetworkStatus = () => {
	const [status, setStatus] = useState(getNetworkStatus())
	const updateNetwork = () => {
		setStatus(window.navigator.onLine)
	}
	useEffect(() => {
		window.addEventListener('online', updateNetwork)
		window.addEventListener('offline', updateNetwork)
		return () => {
			window.removeEventListener('online', updateNetwork)
			window.removeEventListener('offline', updateNetwork)
		}
	}, [])

	return status
}
