import React from 'react'

export const UK = ({size = 24, color = 'currentColor', ...props}) => (
	<svg {...props} xmlns='http://www.w3.org/2000/svg' width={size * 2} height={size} viewBox='0 0 60 30'>
		<clipPath id='t'>
			<path d='M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z' />
		</clipPath>
		<path d='M0,0 v30 h60 v-30 z' fill='#00247d' />
		<path d='M0,0 L60,30 M60,0 L0,30' stroke='#fff' stroke-width='6' />
		<path d='M0,0 L60,30 M60,0 L0,30' clip-path='url(#t)' stroke='#cf142b' stroke-width='4' />
		<path d='M30,0 v30 M0,15 h60' stroke='#fff' stroke-width='10' />
		<path d='M30,0 v30 M0,15 h60' stroke='#cf142b' stroke-width='6' />
		<rect width='60' height='30' strokeWidth={5} stroke={color} fill='none' />
	</svg>
)
