import React, {FunctionComponent} from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import GatsbyImage from 'gatsby-image'

interface Props {
	name: string
	alt?: string
}

export const Image: FunctionComponent<Props> = ({name, alt}) => {
	const data = useStaticQuery(graphql`
		query {
			images: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
				edges {
					node {
						name
						relativePath
						childImageSharp {
							fluid(quality: 100) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`)
	const image = data.images.edges.find(o => o.node.relativePath.includes(name))

	return !image ? null : <GatsbyImage alt={alt || name || image.node.name} fluid={image.node.childImageSharp.fluid} />
}
