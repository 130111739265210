import {Facebook, Instagram, Linkedin, Location, Phone, Profile, Triangle, Twitter} from '../components/svg'
import {graphql, useStaticQuery} from 'gatsby'

import {Form} from '../components/netlify'
import {Header} from '../components/header'
import {Image} from '../components/image'
import {Link} from '../components/link'
import React from 'react'
import {Slider} from '../components/slider'
import {t} from 'frenchkiss'

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query IndexQuery {
			site {
				siteMetadata {
					author
					phone
					email
					address {
						street
						city
						postcode
						country
					}
					twitter {
						url
					}
					facebook {
						url
					}
					instagram {
						url
					}
					linkedin {
						url
					}
				}
			}
		}
	`)
	return (
		<div id='index-page'>
			<section id='hero'>
				<header>
					<h1>
						<span>{t('name.first').toUpperCase()}</span>
						<span>{t('name.last').toUpperCase()}</span>
					</h1>
					<h2>{t('title').toUpperCase()}</h2>
					<ul>
						<li>
							<Link to={'tel:' + data.site.siteMetadata.phone}>
								<button className='dark'>
									<Phone />
									<span>{t('tel')}</span>
								</button>
							</Link>
						</li>
						{/* <li>
							<Link to={'tel:' + data.site.siteMetadata.phone}>
								<button className='dark'>
									<Location />
								</button>
							</Link>
						</li>  TODO: Add Current Map Localisation */}
					</ul>
				</header>
				<div className='content'>
					<Image name='logo' />
				</div>
			</section>
			<Header />
			<section id='profile'>
				<header>
					<h2>{t('profile.title').toUpperCase()}</h2>
				</header>
				<div className='content'>
					<p
						dangerouslySetInnerHTML={{
							__html: t('profile.short')
						}}
					/>
					<p
						dangerouslySetInnerHTML={{
							__html: t('register.uk')
						}}
					/>
					<p
						dangerouslySetInnerHTML={{
							__html: t('register.fr')
						}}
					/>
					{/* <Link to='/profile'>
						<button className='dark'>
							<Profile />
							<span>{t('more.profile')}</span>
						</button>
					</Link> */}
				</div>
			</section>
			<section id='specializations'>
				<header>
					<h2>{t('specializations.title').toUpperCase()}</h2>
				</header>
				<div className='content'>
					<ul id='listspe'>
						<li>
							<h4>{t('specializations.headaches.title')}</h4>
							<p>{t('specializations.headaches.content')}</p>
						</li>
						<li>
							<h4>{t('specializations.sports.title')}</h4>
							<p>{t('specializations.sports.content')}</p>
						</li>
						<li>
							<h4>{t('specializations.pediatrics.title')}</h4>
							<p>{t('specializations.pediatrics.content')}</p>
						</li>
						<li>
							<h4>{t('specializations.women.title')}</h4>
							<p>{t('specializations.women.content')}</p>
						</li>
					</ul>
					{/* <Link to='/osteopathy'>
						<button className='dark'>
							<Triangle />
							<span>{t('more.osteopathy')}</span>
						</button>
					</Link> */}
				</div>
			</section>
			<section id='services'>
				<header>
					<h2>{t('services.title').toUpperCase()}</h2>
				</header>
				<div className='content'>
					<p
						dangerouslySetInnerHTML={{
							__html: t('services.content')
						}}
					/>
					<article>
						<header>
							<h3>{t('services.osteopathy.title')}</h3>
						</header>
						<div className='content'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.osteopathy.content_1')
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.osteopathy.content_2')
								}}
							/>
							<h4>{t('services.osteopathy.sports.title')}</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.osteopathy.sports.content')
								}}
							/>
							<h4>{t('services.osteopathy.pregnancy.title')}</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.osteopathy.pregnancy.content')
								}}
							/>
							<h4>{t('services.osteopathy.post_natal.title')}</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.osteopathy.post_natal.content')
								}}
							/>
						</div>
					</article>
					<article>
						<header>
							<h3>{t('services.aquatic.title')}</h3>
						</header>
						<div className='content'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.aquatic.content_1')
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: t('services.aquatic.content_2')
								}}
							/>
						</div>
					</article>

					{/* <Link to='/osteopathy'>
						<button className='dark'>
							<Triangle />
							<span>{t('more.osteopathy')}</span>
						</button>
					</Link> */}
				</div>
			</section>
			<section id='places'>
				<header>
					<h2>{t('places.title').toUpperCase()}</h2>
				</header>
				<div className='content'>
					<p
						dangerouslySetInnerHTML={{
							__html: t('places.description')
						}}
					/>
					<article>
						<header>
							<h3>{t('places.yours.title')}</h3>
						</header>
						<div className='content'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.yours.presentation')
								}}
							/>
						</div>
					</article>
					<article>
						<header>
							<h3>{t('places.messardiere.title')}</h3>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.messardiere.presentation')
								}}
							/>
						</header>
						<div className='content'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.messardiere.content')
								}}
							/>
							<Slider
								animation={false}
								thumbnail={[<Image name='cm-1' />, <Image name='cm-2' />, <Image name='cm-3' />, <Image name='cm-4' />]}
								data={[<Image name='cm-1' />, <Image name='cm-2' />, <Image name='cm-3' />, <Image name='cm-4' />]}
							/>
						</div>
						<footer>
							<h4>{t('contact.phone').toUpperCase()}</h4>
							<Link to={'tel:' + t('places.messardiere.phone')}>{t('places.messardiere.tel')}</Link>
							<h4>{t('contact.email').toUpperCase()}</h4>
							<Link to={'mailto:' + t('places.messardiere.email')}>{t('places.messardiere.email')}</Link>
							<h4>{t('contact.opening_hours').toUpperCase()}</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.messardiere.opening_hours')
								}}
							/>
							<h4>{t('contact.address').toUpperCase()}</h4>
							<address>
								{t('places.messardiere.address.street')}
								<br />
								{t('places.messardiere.address.city')}, {t('places.messardiere.address.postcode')}
								<br />
								{t('places.messardiere.address.country')}
							</address>
							<h4>{t('contact.elsewhere').toUpperCase()}</h4>
							<ul>
								<li>
									<Link to={t('places.messardiere.instagram')}>
										<Instagram color='#03162f' />
									</Link>
								</li>
								<li>
									<Link to={t('places.messardiere.facebook')}>
										<Facebook color='#03162f' />
									</Link>
								</li>
							</ul>
						</footer>
					</article>
					<article>
						<header>
							<h3
								dangerouslySetInnerHTML={{
									__html: t('places.mvi.title')
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.mvi.presentation')
								}}
							/>
						</header>
						<div className='content'>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.mvi.content')
								}}
							/>
							<Slider
								animation={false}
								thumbnail={[<Image name='mvi-1' />, <Image name='mvi-2' />, <Image name='mvi-3' />, <Image name='mvi-4' />]}
								data={[<Image name='mvi-1' />, <Image name='mvi-2' />, <Image name='mvi-3' />, <Image name='mvi-4' />]}
							/>
						</div>
						<footer>
							<h4>{t('contact.phone').toUpperCase()}</h4>
							<Link to={'tel:' + t('places.mvi.phone')}>{t('places.mvi.tel')}</Link>
							<h4>{t('contact.email').toUpperCase()}</h4>
							<Link to={'mailto:' + t('places.mvi.email')}>{t('places.mvi.email')}</Link>
							<h4>{t('contact.opening_hours').toUpperCase()}</h4>
							<p
								dangerouslySetInnerHTML={{
									__html: t('places.mvi.opening_hours')
								}}
							/>
							<h4>{t('contact.address').toUpperCase()}</h4>
							<address>
								{t('places.mvi.address.street')}
								<br />
								{t('places.mvi.address.city')}, {t('places.mvi.address.postcode')}
								<br />
								{t('places.mvi.address.country')}
							</address>
							<h4>{t('contact.elsewhere').toUpperCase()}</h4>
							<ul>
								<li>
									<Link to={t('places.mvi.instagram')}>
										<Instagram color='#03162f' />
									</Link>
								</li>
								<li>
									<Link to={t('places.mvi.facebook')}>
										<Facebook color='#03162f' />
									</Link>
								</li>
							</ul>
						</footer>
					</article>
				</div>
			</section>
			<section id='contact'>
				<header>
					<h2>{t('contact.title').toUpperCase()}</h2>
				</header>
				<div className='content'>
					<p
						dangerouslySetInnerHTML={{
							__html: t('contact.content')
						}}
					/>
					<Form id='Contact'>
						<input type='name' name='name' id='name' placeholder={t('contact.name')} required />
						<input type='email' name='email' id='email' placeholder={t('contact.email')} required />
						<textarea name='message' id='message' placeholder={t('contact.message')} required />
					</Form>
				</div>
				<footer>
					<h4>{t('contact.email').toUpperCase()}</h4>
					<Link to={'mailto:' + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</Link>
					<h4>{t('contact.phone').toUpperCase()}</h4>
					<Link to={'tel:' + data.site.siteMetadata.phone}>{t('tel')}</Link>
					{/* <h4>{t('contact.address').toUpperCase()}</h4>
					<address>
						{data.site.siteMetadata.address.street}
						<br />
						{data.site.siteMetadata.address.city}, {data.site.siteMetadata.address.postcode}
						<br />
						{data.site.siteMetadata.address.country}
					</address> */}
					<h4>{t('contact.elsewhere').toUpperCase()}</h4>
					<ul>
						<li>
							<Link to={data.site.siteMetadata.linkedin.url}>
								<Linkedin color='#03162f' />
							</Link>
						</li>
						<li>
							<Link to={data.site.siteMetadata.twitter.url}>
								<Twitter color='#03162f' />
							</Link>
						</li>
						<li>
							<Link to={data.site.siteMetadata.instagram.url}>
								<Instagram color='#03162f' />
							</Link>
						</li>
						<li>
							<Link to={data.site.siteMetadata.facebook.url}>
								<Facebook color='#03162f' />
							</Link>
						</li>
					</ul>
				</footer>
			</section>
		</div>
	)
}
export default IndexPage
