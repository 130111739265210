import {AnimatePresence, motion} from 'framer-motion'
import React, {FunctionComponent, useState} from 'react'

import {useInterval} from './hooks'

interface Props {
	data: JSX.Element[]
	thumbnail?: JSX.Element[]
	dots?: boolean
	animation?: boolean | 'dynamic'
	delay?: number
}

export const Slider: FunctionComponent<Props> = ({data, thumbnail, animation = false, dots = false, delay = 6000, ...props}) => {
	const [index, setIndex] = useState(0)
	const [state, setState] = useState(!animation ? false : true)

	const handleChange = (i: number) => {
		const x = (index + i) % data.length
		setIndex(x < 0 ? data.length - 1 : x)
	}

	useInterval(
		() => {
			handleChange(+1)
		},
		state ? delay + index : null
	)

	return (
		<div {...props} id='slider'>
			<div id='content'>
				{!thumbnail && (
					<>
						<motion.button
							animate
							onClick={() => {
								animation !== 'dynamic' && setState(false)
								handleChange(-1)
							}}>
							&larr;
						</motion.button>
						<motion.button
							animate
							onClick={() => {
								animation !== 'dynamic' && setState(false)
								handleChange(+1)
							}}>
							&rarr;
						</motion.button>
					</>
				)}
				<AnimatePresence initial={false}>
					<motion.div
						key={index}
						initial='collapsed'
						animate='open'
						exit='collapsed'
						variants={{
							open: {opacity: 1, height: 'auto'},
							collapsed: {opacity: 0, height: 0}
						}}
						transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}>
						{data[index]}
					</motion.div>
				</AnimatePresence>
			</div>
			{dots && (
				<ul>
					{data.map((item, i) => (
						<li
							className={i === index ? 'dot active' : 'dot'}
							onClick={() => {
								animation !== 'dynamic' && setState(false)
								setIndex(i)
							}}
						/>
					))}
				</ul>
			)}
			{thumbnail && (
				<ul>
					{thumbnail.map(
						(item, i) =>
							i !== index && (
								<li
									className='thumbnail'
									onClick={() => {
										animation !== 'dynamic' && setState(false)
										setIndex(i)
									}}>
									{item}
								</li>
							)
					)}
				</ul>
			)}
		</div>
	)
}
