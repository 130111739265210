import {useEffect, useRef} from 'react'

export const useInterval = (callback, delay) => {
	const savedCallback = useRef()
	const handler = (...args) => savedCallback.current(...args)
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])
	useEffect(() => {
		if (delay !== null) {
			const id = setInterval(handler, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}
