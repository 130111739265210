import {AnimatePresence, motion} from 'framer-motion'
import {FR, Menu, UK} from './svg'
import React, {FunctionComponent, useRef, useState} from 'react'

import {Link} from './link'
import {useLanguage} from './localization'

interface SVGElement extends Element {
	beginElement(): SVGElement
}

interface Link {
	label: string
	path: string
}

interface Props {
	data: Link[]
}

export const Navigation: FunctionComponent<Props> = ({data, ...props}) => {
	const language = useLanguage()
	const [state, setState] = useState(false)
	const animate = useRef<SVGElement>()
	const toggle = () => {
		setState(!state)
		animate.current.beginElement()
	}
	return (
		<AnimatePresence initial={false}>
			{/* className='w-8 h-8 mr-6 focus:outline-none md:hidden hover:text-red' */}
			<div style={{display: 'flex', alignItems: 'center'}}>
				{language.KEY === 'en_GB' && (
					<Link to='/' language={{KEY: 'fr_FR', DEFAULT: true, PATH: '/fr'}}>
						<FR className='flag' size={16} />
					</Link>
				)}
				{language.KEY === 'fr_FR' && (
					<Link to='/' language={{KEY: 'en_GB', DEFAULT: false, PATH: '/en'}}>
						<UK className='flag' size={16} />
					</Link>
				)}
				<button
					aria-label='Reveal navigation' // FIXME: Need TRADUCTION
					aria-pressed={state}
					onClick={toggle}>
					<Menu animation={{state: state, reference: animate}} color='#03162f' />
				</button>
			</div>
			{state && (
				<motion.ul
					id='navlist'
					key='menu'
					initial='closed'
					animate='open'
					exit='closed'
					variants={{
						open: {
							opacity: 1,
							height: 'auto',
							transition: {duration: 0.8, ease: [0.05, 0.6, 0.2, 0.9], staggerChildren: 0.07, delayChildren: 0.2}
						},
						closed: {
							opacity: 0,
							height: 0,
							transition: {duration: 0.8, ease: [0.9, 0.6, 0.4, 0.9], staggerChildren: 0.07, staggerDirection: -1}
						}
					}}>
					{/*<nav className='flex items-center ml-auto' {...props} key='navigation'>
					 						className='flex flex-col items-center w-screen md:w-auto md:flex-row'
					 */}
					{data.map((link, i) => (
						// className='mx-6 my-4 md:my-0 tracking-wide text-l'
						<motion.li
							onClick={toggle}
							key={`navlink_${i}`}
							variants={{
								open: {
									y: 0,
									opacity: 1,
									transition: {
										y: {stiffness: 1000, velocity: -100}
									}
								},
								closed: {
									y: -50,
									opacity: 0,
									transition: {
										y: {stiffness: 1000}
									}
								}
							}}>
							<Link to={link.path} aria-label={link.label}>
								{link.label}
							</Link>
						</motion.li>
					))}
				</motion.ul>
			)}
		</AnimatePresence>
	)
}
