import React, {FunctionComponent} from 'react'
import {useScroll, useWindowSize} from './hooks'

import {Image} from './image'
import {Link} from './link'
import {Navigation} from './navigation'
import {motion} from 'framer-motion'
import {t} from 'frenchkiss'

interface Props {}

export const Header: FunctionComponent<Props> = ({...props}) => {
	const size = useWindowSize()
	const scroll = useScroll()
	const height = 75

	return (
		<motion.header
			hidden={scroll.Y <= size.height - height}
			id='header-bar'
			style={{opacity: (scroll.Y - (size.height - height)) / height}}
			{...props}>
			<nav>
				<Link id='logo' to='/' aria-label='Back to home page'>
					<Image name='logo' />
				</Link>
				<Navigation
					data={[
						{
							label: t('profile.title'),
							path: '/#profile'
						},
						{
							label: t('specializations.title'),
							path: '/#specializations'
						},
						{
							label: t('services.title'),
							path: '/#services'
						},
						{
							label: t('places.title'),
							path: '/#places'
						},
						{
							label: t('contact.title'),
							path: '/#contact'
						}
					]}
				/>
			</nav>
		</motion.header>
	)
}
