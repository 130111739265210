import {useEffect, useState} from 'react'

export const useWindowSize = () => {
	const getSize = () =>
		typeof window !== 'undefined'
			? {
					width: window.innerWidth,
					height: window.innerHeight
			  }
			: {
					width: undefined,
					height: undefined
			  }

	const [windowSize, setWindowSize] = useState(getSize)

	const handleResize = () => setWindowSize(getSize())

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}
