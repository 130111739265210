import {useEffect, useState} from 'react'

export const useScroll = () => {
	const [lastScrollTop, setLastScrollTop] = useState(0)
	const [bodyOffset, setBodyOffset] = useState<DOMRect | ClientRect>(
		typeof document !== 'undefined' && document.body.getBoundingClientRect()
	)
	const [Y, setY] = useState<number>(bodyOffset.top)
	const [X, setX] = useState<number>(bodyOffset.left)
	const [direction, setDirection] = useState<'up' | 'down'>()

	const listener = e => {
		setBodyOffset(document.body.getBoundingClientRect())
		setY(-bodyOffset.top)
		setX(bodyOffset.left)
		setDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
		setLastScrollTop(-bodyOffset.top)
	}

	useEffect(() => {
		window.addEventListener('scroll', listener)
		return () => window.removeEventListener('scroll', listener)
	})

	return {
		Y,
		X,
		direction
	}
}
