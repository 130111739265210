import React from 'react'

export const FR = ({size = 24, color = 'currentColor', ...props}) => (
	<svg {...props} xmlns='http://www.w3.org/2000/svg' width={size / (2 / 3)} height={size} viewBox='0 0 900 600'>
		<rect width='900' height='600' fill='#ED2939' />
		<rect width='600' height='600' fill='#fff' />
		<rect width='300' height='600' fill='#002395' />
		<rect width='900' height='600' strokeWidth={90} stroke={color} fill='none' />
	</svg>
)
