import React, {FunctionComponent, MutableRefObject} from 'react'

export interface Props {
	size?: number
	color?: string
	animation: {
		state: boolean
		reference: MutableRefObject<any>
	}
}

export const Menu: FunctionComponent<Props> = ({animation, size = 24, color = 'currentColor', ...props}) => (
	<svg {...props} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 100 100' style={{stroke: color}}>
		<line x1='10' y1='40' x2='70' y2='40' strokeWidth='5' strokeLinecap='round'>
			{animation && (
				<>
					<animate
						id='anim'
						ref={animation.reference}
						attributeName='x1'
						dur='500ms'
						fill='freeze'
						values={animation.state ? '10;30' : '30;10'}
					/>
					<animate begin='anim.begin' attributeName='y1' dur='500ms' fill='freeze' values={animation.state ? '40;30' : '30;40'} />
					<animate begin='anim.begin' attributeName='y2' dur='500ms' fill='freeze' values={animation.state ? '40;70' : '70;40'} />
				</>
			)}
		</line>
		<line x1='30' y1='60' x2='90' y2='60' strokeWidth='5' strokeLinecap='round'>
			{animation && (
				<>
					<animate begin='anim.begin' attributeName='x2' dur='500ms' fill='freeze' values={animation.state ? '90;70' : '70;90'} />
					<animate begin='anim.begin' attributeName='y1' dur='500ms' fill='freeze' values={animation.state ? '60;70' : '70;60'} />
					<animate begin='anim.begin' attributeName='y2' dur='500ms' fill='freeze' values={animation.state ? '60;30' : '30;60'} />
				</>
			)}
		</line>
	</svg>
)
