// TODO: Add recaptcha

import React, {FunctionComponent, useState} from 'react'

import NProgress from 'nprogress'
import {Send} from '../svg'
import {t} from 'frenchkiss'
import {useNetworkStatus} from '../hooks'

interface Props {
	id: string
}

export const Form: FunctionComponent<Props> = ({id, children, ...props}) => {
	const [status, setStatus] = useState(-10)
	const networkStatus = useNetworkStatus()
	const handleSubmit = e => {
		NProgress.start()
		setStatus(0)
		fetch('/', {
			method: 'POST',
			body: new FormData(e.target)
		})
			.then(() => {
				setStatus(1)
				NProgress.done()
			})
			.catch(error => {
				setStatus(-1)
				NProgress.done()
			})
		e.preventDefault()
	}
	const submitLabel = (e: number) => {
		switch (e) {
			case 0:
				return t('form.sending')
			case 1:
				return t('form.sent')
			default:
				return t('form.send')
		}
	}
	return (
		<form name={id} onSubmit={handleSubmit} data-netlify='true' data-netlify-honeypot='turing-test'>
			<input type='hidden' name='form-name' value={id} />
			<input type='hidden' name='turing-test' />
			<fieldset disabled={networkStatus ? status >= 0 : true}>
				{children}
				<button type='submit' className='dark'>
					<Send />
					<span>{networkStatus ? submitLabel(status) : t('network.offline')}</span>
				</button>
				{status === 1 && <span>{t('form.success')}</span>}
				{status === -1 && <span>{t('form.error')}</span>}
			</fieldset>
		</form>
	)
}
