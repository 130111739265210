import React, {FunctionComponent} from 'react'

import {GatsbyLinkProps} from 'gatsby'
import {LocalizedLink} from './localization'

const isHash = (o: string) => /^#/.test(o)
const isInternal = (o: string) => /^\/(?!\/)/.test(o)

export const Link: FunctionComponent<GatsbyLinkProps<{}>> = ({to, ...props}) =>
	isHash(to) ? (
		<a href={to} {...props} />
	) : !isInternal(to) ? (
		<a href={to} target='_blank' rel='noreferrer noopener' {...props} />
	) : (
		<LocalizedLink to={to} {...props} />
	)
